import React from "react"
import ContactUsTemplate from "../pagesTemplates/contact-us"
import gatsbyi18Context from "../contexts/gatsby-i18n-context.js"

export default props => {
  const { pageContext } = props
  const { langKey: language } = pageContext

  const data = {
    language,
  }

  return (
    <gatsbyi18Context.Provider value={data}>
      <ContactUsTemplate {...props} />
    </gatsbyi18Context.Provider>
  )
}
