import React from "react"
import { useTranslation } from 'react-i18next'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'
import ContactUs from '../components/contact-us'

export default () => {
  const { t } = useTranslation()

  return (
    <Layout className="has-header">
      <SEO title={t('contact')} />
      <Header />
      <ContactUs title={true} />
      <Footer />
    </Layout>
  )
}
