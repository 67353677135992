import React, { useState, useEffect } from 'react'
import {useTranslation} from 'react-i18next'

const API_URL = 'https://wordpress.almadaq.net'

export default ({title}) => {
  const { t } = useTranslation()
  const [mailReady, setMailReady] = useState(false)
  const [newsletterReady, setNewsletterReady] = useState(false)
  const [mailLoading, setMailLoading] = useState(false)
  const [mailLog, setMailLog] = useState(null)

  const [nlLoading, setNlLoading] = useState(false)
  const [nlLog, setNlLog] = useState(null)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [newsletterEmail, setNewsletterEmail] = useState('')

  const resetEmail = () => {
    setName('')
    setEmail('')
    setSubject('')
    setMessage('')
  }

  const resetNewsletter = () => {
    setNewsletterEmail('')
  }

  const handleMailSubmission = async e => {
    e.preventDefault()
    setMailLoading(true)
    setMailLog(null)

    try {
      await fetch(`${API_URL}/wp-json/contact/send`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        json: true,
        body: JSON.stringify({
          name,
          email,
          subject,
          message
        })
      }).then(
        response => response.json()
      )

      resetEmail()
      setMailLog({
        success: true,
        message: null,
      })
    } catch (e) {
      setMailLog({
        success: false,
        message: e.message,
      })
    } finally {
      setMailLoading(false)
    }
  }

  const handleNlSubmission = async e => {
    e.preventDefault()
    setNlLoading(true)
    setNlLog(null)

    try {
      await fetch(`${API_URL}/wp-json/newsletter/subscribe`, {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        json: true,
        body: JSON.stringify({
          email: newsletterEmail,
        })
      }).then(
        response => response.json()
      )

      resetNewsletter()
      setNlLog({
        success: true,
        message: null,
      })
    } catch (e) {
      setNlLog({
        success: false,
        message: e.message,
      })
    } finally {
      setNlLoading(false)
    }
  }

  useEffect(
    () => {
      if (
        name
        && email
        && subject
        && message
      ) {
        setMailReady(true)
      } else {
        setMailReady(false)
      }
    }
  )

  useEffect(
    () => {
      if (
        newsletterEmail
      ) {
        setNewsletterReady(true)
      } else {
        setNewsletterReady(false)
      }
    }
  )

  return (
    <div className="contact-us min-full-height">
      <div className="container">
        <article className="py-4">
          <div className="decorator d-none d-lg-block my-5"></div>
          {
            (
              title && (
                <header className="mb-4">
                  <h1 className="mb-0">
                    {t('contact')}
                  </h1>
                </header>
              )
            ) || null
          }
          <section>
            {
              mailLog && (
                <div className="mb-4">
                  {
                    mailLog.success && (
                      <div className="alert alert-success">
                        {t('message has been sent')}
                      </div>
                    )
                  }

                  {
                    !mailLog.success && (
                      <div className="alert alert-danger">
                        {t('error')}
                      </div>
                    )
                  }
                </div>
              )
            }
            <form onSubmit={handleMailSubmission} autoComplete="off" className="mb-4">
              <fieldset disabled={mailLoading}>
                <div className="row">
                  <div className="col-lg-5 order-lg-1 order-2">
                    <div className="h-100 d-flex flex-column justify-content-between">
                      <div className="form-group">
                        <input onChange={e => setName(e.target.value)} name="name" type="text" className="form-control" placeholder={t('name')} value={name} />
                      </div>
                      <div className="form-group">
                        <input onChange={e => setEmail(e.target.value)} name="email" type="email" className="form-control" placeholder={t('email')} value={email} />
                      </div>
                      <div className="form-group mb-4 mb-lg-0">
                        <button disabled={!mailReady} type="submit" className="btn btn-block">
                          {
                            mailLoading && (
                              <>
                                {t('send')}&nbsp;
                                <i className="fa fa-spinner fa-pulse"></i>
                              </>
                            )
                          }

                          {
                            !mailLoading && (
                              <>
                                {t('send')}
                              </>
                            )
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 order-lg-2 order-1 mb-3 mb-lg-0">
                    <div className="form-group">
                      <input onChange={e => setSubject(e.target.value)} name="subject" type="text" className="form-control" placeholder={t('subject')} value={subject} />
                    </div>
                    <div className="form-group mb-0">
                      <textarea onChange={e => setMessage(e.target.value)} className="form-control h-100" name="message" cols="30" rows="4" value={message}></textarea>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>

            <form onSubmit={handleNlSubmission} autoComplete="off" className="d-none d-lg-block">
              {
                nlLog && (
                  <div className="mb-4">
                    {
                      nlLog.success && (
                        <div className="alert alert-success">
                          {t('subscribed successfuly')}
                        </div>
                      )
                    }

                    {
                      !nlLog.success && (
                        <div className="alert alert-danger">
                          {t('error')}
                        </div>
                      )
                    }
                  </div>
                )
              }
              <fieldset disabled={nlLoading}>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      <input onChange={e => setNewsletterEmail(e.target.value)} type="email" className="form-control" placeholder={t('email')} value={newsletterEmail} />
                      <div className="input-group-append">
                        <button disabled={!newsletterReady} className="btn py-0 px-5">
                          {
                            !nlLoading && (
                              <>
                                {t('subscribe')}
                              </>
                            )
                          }

                          {
                            nlLoading && (
                              <>
                                {t('subscribe')}
                                &nbsp;
                                <i className="fa fa-spinner fa-pulse"></i>
                              </>
                            )
                          }
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </section>
          <div className="decorator d-none d-lg-block my-5 mr-auto"></div>
        </article>
      </div>
    </div>
  )
}
